export default {
  "general.account.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
  "general.countries.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Netherlands"])},
  "general.countries.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
  "general.languages.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "general.languages.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
  "general.languages.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "registered.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your registration"])},
  "registered.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our event managers will contact you shortly with further instructions & possibilities"])},
  "cta.registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View your volunteer overview page"])},
  "forms.motivations.heading.volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What motivation do you have to volunteer?"])},
  "forms.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all information provided is complete or valid, please verify"])},
  "forms.areas.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In which region do you want to be active as a volunteer?"])},
  "forms.location.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language and location"])},
  "forms.location.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no country or task filled in"])},
  "registration.individual.tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
  "registration.group.tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "registration.group.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible"])},
  "registration.group.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Volunteer Registration"])},
  "registration.group.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as"])},
  "forms.remarks.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe yourself here"])},
  "general.language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "general.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "forms.size.small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 - 10"])},
  "forms.size.medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 - 40"])},
  "forms.size.large": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["41+ or more"])},
  "forms.size.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No choice has been chosen"])},
  "forms.size.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many volunteers can you engage with your club/organization?"])},
  "general.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of your club/organization"])},
  "general.extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other motivation"])},
  "forms.extra.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No choice has been chosen"])},
  "forms.club.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No name was entered"])},
  "general.firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "general.lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "forms.birthdate.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
  "forms.birthdate.errors.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no date of birth filled in"])},
  "forms.birthdate.errors.age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, you can only register from the year you turn 16"])},
  "general.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "forms.email.error.unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is already a Letsmove.com account associated with this email address. Log in with your existing account and fill out the form."])},
  "forms.email.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address is a required field"])},
  "cta.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to your Letsmove.com account"])},
  "forms.email.error.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no valid email address entered"])},
  "forms.locales.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In what region is your club/organization active?"])},
  "forms.locales.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a zip code in the area where your club/organization would like to volunteer."])},
  "forms.motivations.heading.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the motivation of your club/organization to volunteer?"])},
  "forms.motivations.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tick everything that applies"])},
  "forms.motivations.money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To earn a little extra money"])},
  "forms.motivations.fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For fun, to be part of the event"])},
  "forms.motivations.social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the social aspect"])},
  "forms.motivations.learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To gain new experiences or skills"])},
  "forms.motivations.extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other motivation"])},
  "forms.postcode.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no valid postal code entered"])},
  "general.postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
  "forms.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "forms.tel.error.numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only use numbers in this field"])},
  "forms.tel.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell phone is a required field"])},
  "general.tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell phone"])},
  "forms.remarks.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other remarks"])},
  "forms.responsible.me.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register me as responsible of a group"])},
  "forms.responsible.me.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As the responsible person of a club, you will receive all communications from Golazo regarding the event."])},
  "forms.responsible.other.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register someone else as responsible person"])},
  "forms.responsible.other.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let the responsible person register the group through this form. The responsible person gets a personal account to manage the group registration."])},
  "forms.responsible.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make a choice"])},
  "forms.privacy.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I declare to have taken knowledge of the"])},
  "forms.privacy.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Statement."])},
  "forms.pre-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This information comes from your Letsmove.com account. Additions are automatically written to your Letsmove.com account"])},
  "general.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "forms.password.panel.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a password"])},
  "forms.password.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestions"])},
  "forms.password.requirement-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one lower case letter"])},
  "forms.password.requirement-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one capital letter"])},
  "forms.password.requirement-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one number"])},
  "forms.password.requirement-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 8 characters"])},
  "forms.password.error.required.unused-translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must be at least 8 characters long and contain at least 1 letter and 1 number"])},
  "forms.password.error.weak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a rather weak password, but we will accept it."])},
  "forms.password.error.strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay, strong password!"])},
  "general.days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "general.months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "general.years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "forms.terms.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the terms and conditions*"])},
  "forms.terms.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree"])},
  "forms.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will get an individual Letsmove.com account that allows you to manage your volunteer profile, both for groups and individuals."])},
  "cta.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "logout.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hope to see you soon"])},
  "logout.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged out successfully"])},
  "logout.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Golazo"])},
  "logout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "cta.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Golazo"])},
  "login.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "login.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email to continue"])},
  "login.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to volunteer registration"])},
  "login.form.email.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Email Address"])},
  "login.form.email.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure it's your personal (email address?)"])},
  "login.form.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "login.form.footer.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or"])},
  "login.form.footer.faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a question or need help?"])},
  "login.form.footer.privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy and conditions*"])},
  "login.form.footer.registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new account"])},
  "cta.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "overview.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volunteer Overview"])},
  "overview.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volunteers"])},
  "cta.register.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a new club or organization"])},
  "cta.register.volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as a volunteer"])},
  "overview.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
  "faq.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "faq.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview of all practical questions you might have as a volunteer."])},
  "cta.faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the FAQ"])},
  "management.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your volunteer registration"])},
  "management.description.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I want to remove myself from the platform"])},
  "management.description.organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I want to delete this volunteer registration"])},
  "cta.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm that you want to delete this"])},
  "toast.manage.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully deleted"])},
  "forms.tasks.heading.organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What type of task/tasks is your organization interested in?"])},
  "forms.tasks.heading.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What type of task/tasks are you interested in?"])},
  "forms.tasks.steward.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshalls & stewards"])},
  "forms.tasks.steward.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible & trustworthy, safety first minded and observant, assertive and cooperative"])},
  "forms.tasks.service.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Team"])},
  "forms.tasks.service.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hands-on problem solvering, a Jack/Jill of all trades and a team player"])},
  "forms.tasks.welcome.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome & Info Team"])},
  "forms.tasks.welcome.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessible, warm, and solution-minded, happiness-maker, supportive and creative, multilingual"])},
  "forms.tasks.green.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Team"])},
  "forms.tasks.green.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hands-on in a environmentally conscious way, future- and quality oriented, sustainable and social interested"])},
  "countries.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
  "countries.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
  "forms.motivations.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No choice has been chosen"])},
  "forms.tasks.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No choice has been chosen"])},
  "forms.firstname.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no first name filled in"])},
  "forms.lastname.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No surname has been entered"])},
  "forms.general.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
  "forms.password.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No password has been filled in "])},
  "forms.volunteer.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No other motivation is specified"])}
}